import React from "react";
import { FaXTwitter, FaMedium } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerTitleContainer}>
        <div className={styles.contactInfo}>
          <a
            href="https://x.com/BullishBanterS"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Follow us on <FaXTwitter className={styles.icon} />
          </a>
          <span className={styles.separator}>|</span>
          <a
            href="https://medium.com/@bullishbantersociety"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Read us on <FaMedium className={styles.icon} />
          </a>
          <span className={styles.separator}>|</span>
          <a href="mailto:info@bullishbanter.net" className={styles.link}>
            <MdOutlineMailOutline
              className={`${styles.icon} ${styles.emailIcon}`}
            />{" "}
            info@bullishbanter.net
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
