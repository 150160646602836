import React from "react";
import PropTypes from "prop-types";
import styles from "./DateDisplay.module.css";

const DateDisplay = ({ date }) => {
  return <div className={styles.date}>{date}</div>;
};

DateDisplay.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateDisplay;
