import React from "react";
import "./FearGreedIndex.css";

const FearGreedIndex = ({ value }) => {
  const getColor = (value) => {
    if (value < 1) return "#D32F2F";
    if (value < 2) return "#FF5722";
    if (value < 3) return "#FFD700";
    if (value < 4) return "#8BC34A";
    return "#388E3C";
  };

  const getText = (value) => {
    if (value < 1) return "EXTREME FEAR";
    if (value < 2) return "FEAR";
    if (value < 3) return "NEUTRAL";
    if (value < 4) return "GREED";
    return "EXTREME GREED";
  };

  const segmentCount = 5;
  const segmentWidth = 100 / segmentCount;
  const pointerPosition = ((value + 1) / segmentCount) * 100;

  return (
    <div className="fear-greed-index">
      <div className="index-bar">
        <div className="index-segments">
          {[...Array(5)].map((_, i) => (
            <div
              key={i}
              className="segment"
              style={{ backgroundColor: getColor(i + 0.5) }}
            />
          ))}
        </div>
        <div
          className="index-pointer"
          style={{ left: `calc(${pointerPosition}% - ${segmentWidth / 2}%)` }}
        />
      </div>
      <div className="index-value">{getText(value)}</div>
    </div>
  );
};

export default FearGreedIndex;
