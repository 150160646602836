import React from "react";
import styles from "./StockCard.module.css";
import StarRating from "../StarRating/StarRating";
import FearGreedIndex from "../FearGreedIndex/FearGreedIndex";
import StockChart from "../StockChart/StockChart";

const financialDataTitles = {
  pe_ratio: "P/E Ratio",
  pb_ratio: "P/B Ratio",
  roe: "ROE",
  net_profit_margin: "Net Profit Margin",
  gross_margin: "Gross Margin",
  roa: "ROA",
  ebitda: "EBITDA",
  eps_growth: "EPS Growth",
  asset_turnover: "Asset Turnover",
  operating_margin: "Operating Margin",
  debt_to_equity_ratio: "Debt to Equity Ratio",
  current_ratio: "Current Ratio",
  quick_ratio: "Quick Ratio",
  interest_coverage_ratio: "Interest Coverage Ratio",
  free_cash_flow: "Free Cash Flow",
  revenue: "Revenue",
  operating_cash_flow_to_net_income: "Operating Cash Flow to Net Income",
  dividend_yield: "Dividend Yield",
};

const StockCard = ({ stock }) => {
  // Split the financial data into two chunks
  const financialDataEntries = Object.entries(stock.financial_data).filter(
    ([key]) => financialDataTitles[key]
  );

  const half = Math.ceil(financialDataEntries.length / 2);
  const firstHalf = financialDataEntries.slice(0, half);
  const secondHalf = financialDataEntries.slice(half);

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.nameContainer}>
          <h2>
            {stock.full_name} (
            <a
              href={`https://finance.yahoo.com/quote/${stock.symbol}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.stockLink}
            >
              {stock.symbol}
            </a>
            )
          </h2>
        </div>
      </div>
      <div className={styles.stockContent}>
        <div className={styles.predictionWrapper}>
          <div className={styles.priceSection}>
            <div className={styles.priceBlock}>
              <h4>Current Price</h4>
              <p className={styles.currentPrice}>
                ${formatRatio(stock.current_price, 2)}
              </p>
            </div>
            <div className={styles.priceBlock}>
              <h4>Predicted Price</h4>
              <p className={styles.predictedPrice}>
                ${formatRatio(stock.predicted_price, 2)}
              </p>
            </div>
            <div className={styles.priceBlock}>
              <h4>Predicted Price Change</h4>
              <p className={styles.percentageChange}>
                <span
                  className={
                    stock.percentage_change >= 0
                      ? `${styles.positiveChange} ${styles.changeIcon}`
                      : `${styles.negativeChange} ${styles.changeIcon}`
                  }
                >
                  <span className={styles.arrowIcon}>
                    {stock.percentage_change >= 0 ? "↑" : "↓"}
                  </span>{" "}
                  {formatRatio(stock.percentage_change, 2)}%
                </span>
              </p>
            </div>
            <div className={styles.priceBlock}>
              <h4>Fear/Greed Index</h4>
              <FearGreedIndex
                value={stock.fear_greed_index}
                min={0}
                max={100}
              />
            </div>
          </div>
        </div>
        <div className={styles.stockImage}>
          <StockChart
            data={stock.financial_data}
            predictions={stock.predictions}
            trend={stock.trend}
            symbol={stock.symbol}
          />
        </div>
      </div>
      <div className={styles.financialScoreWrapper}>
        <div>
          <h3>Financial Data</h3>
        </div>
        <div>
          <StarRating score={stock.financial_data.financial_total_score} />
        </div>
      </div>
      <div className={styles.financialTableWrapper}>
        <table className={styles.financialTable}>
          <tbody>
            {firstHalf.map((_, index) => (
              <tr key={index}>
                <td>
                  <strong>{financialDataTitles[firstHalf[index][0]]}</strong>
                </td>
                <td>
                  {firstHalf[index][1] !== null
                    ? formatNumber(firstHalf[index][1])
                    : "N/A"}
                </td>
                {secondHalf[index] && (
                  <>
                    <td>
                      <strong>
                        {financialDataTitles[secondHalf[index][0]]}
                      </strong>
                    </td>
                    <td>
                      {secondHalf[index][1] !== null
                        ? formatNumber(secondHalf[index][1])
                        : "N/A"}
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const formatRatio = (value, decimalPlaces = 2) => {
  value = parseFloat(value);
  return isNaN(value) ? "N/A" : value.toFixed(decimalPlaces);
};

const formatNumber = (value) => {
  value = parseFloat(value);
  if (isNaN(value)) return "N/A";
  if (Math.abs(value) >= 1.0e9) {
    return (value / 1.0e9).toFixed(2) + "B";
  } else if (Math.abs(value) >= 1.0e6) {
    return (value / 1.0e6).toFixed(2) + "M";
  } else if (Math.abs(value) >= 1.0e3) {
    return (value / 1.0e3).toFixed(2) + "K";
  } else {
    return value.toFixed(2);
  }
};

export default StockCard;
