import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const StockChart = ({ predictions, symbol }) => {
  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!predictions) setError("Missing predictions");
    else if (!symbol) setError("Missing symbol");
    else if (predictions.length === 0) setError("Predictions are empty");
    else {
      try {
        const realData = predictions.filter((item) => item.Close !== null);

        const chartData = {
          labels: predictions.map((item) => new Date(item.ds)),
          datasets: [
            {
              label: "Real Data",
              data: realData.map((item) => ({
                x: new Date(item.ds),
                y: item.Close,
              })),
              borderColor: "cyan",
              borderWidth: 2,
              fill: false,
              pointRadius: 0,
              pointHoverRadius: 0,
              tension: 0,
            },
            {
              label: "Predictions",
              data: predictions.map((item) => ({
                x: new Date(item.ds),
                y: item.yhat,
              })),
              borderColor: "red",
              borderWidth: 2,
              borderDash: [5, 5],
              fill: false,
              pointRadius: 0,
            },
            {
              label: "Trend",
              data: predictions.map((item) => ({
                x: new Date(item.ds),
                y: item.trend,
              })),
              borderColor: "limegreen",
              borderWidth: 2,
              fill: false,
              pointRadius: 0,
            },
            {
              label: "Prediction Upper",
              data: predictions.map((item) => ({
                x: new Date(item.ds),
                y: item.yhat_upper,
              })),
              borderColor: "rgba(255, 0, 0, 0.7)",
              borderWidth: 1.2,
              backgroundColor: "rgba(255, 0, 0, 0.1)",
              pointRadius: 0,
              fill: "+1",
            },
            {
              label: "Prediction Lower",
              data: predictions.map((item) => ({
                x: new Date(item.ds),
                y: item.yhat_lower,
              })),
              borderColor: "rgba(255, 0, 0, 0.7)",
              borderWidth: 1,
              backgroundColor: "rgba(255, 0, 0, 0.1)",
              pointRadius: 0,
              fill: false,
            },
          ],
        };

        setChartData(chartData);
        setError(null);
      } catch (err) {
        console.error("Error processing data:", err);
        setError(`Error processing data: ${err.message}`);
      }
    }
  }, [predictions, symbol]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        title: {
          display: false,
          text: "",
          color: "white",
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          color: "white",
          padding: 5,
        },
        grid: {
          color: "rgb(210,210,211,0.15)",
          drawTicks: false,
        },
      },
      y: {
        title: {
          display: false,
          color: "white",
        },
        ticks: {
          color: "white",
        },
        grid: {
          color: "rgb(210,210,211,0.15)",
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: (tooltipItems) => {
            const date = tooltipItems[0].raw.x;
            const formattedDate = new Date(date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            });
            return formattedDate;
          },
        },
      },
      legend: {
        position: "top",
        align: "end",
        labels: {
          font: {
            size: 12,
          },
          boxWidth: 12,
          boxHeight: 12,
          color: "white",
        },
      },
    },
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!chartData) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default StockChart;
