import React from "react";
import styles from "./InformationCard.module.css";
import { FaBitcoin, FaEthereum } from "react-icons/fa";

const InformationCard = () => {
  return (
    <div className={styles.card}>
      <h2>Disclaimer</h2>
      <p>
        <b>
          The predictions provided here are generated by statistical models and
          are not professional financial advice.
        </b>{" "}
        These forecasts are designed to give you an additional perspective, but
        they should not be the sole basis for your investment decisions. Markets
        can change rapidly due to various factors, and our predictions may not
        account for all of them. <b>Always conduct your own research.</b>
      </p>

      <p>
        This project is currently in a <b>beta phase</b>, and the accuracy of
        predictions may vary as we continue to improve our models.{" "}
        <b>
          By using this information, you acknowledge that you understand the
          risks involved.
        </b>{" "}
        Remember that all investments carry the possibility of loss, and past
        performance doesn't guarantee future results. You are solely responsible
        for evaluating the risks and determining whether an investment is
        appropriate for your situation.
      </p>

      <h2>Support Us</h2>
      <p>
        Every contribution, no matter the size, propels us forward. Together, we
        can build more powerful and reliable financial tools for everyone. Ready
        to make an impact? Donate now and be a part of our journey!
      </p>

      <div className={styles.wallets}>
        <div className={styles.wallet}>
          <h4 className={styles.walletTitle}>
            <FaBitcoin className={styles.icon} /> BTC
          </h4>
          <p className={styles.walletAddress}>
            bc1q46dwr8ewcmmfhrs3t6c70n47zrt4p2f0rq2ted
          </p>
        </div>
        <div className={styles.wallet}>
          <h4 className={styles.walletTitle}>
            <FaEthereum className={styles.icon} /> ETH
          </h4>
          <p className={styles.walletAddress}>
            0x5E0Ce8Fae144E799cf401add23e38e637667bc80
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformationCard;
