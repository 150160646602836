import React from "react";
import StockCard from "../StockCard/StockCard";
import styles from "./PredictionsSection.module.css";

const PredictionsSection = ({ title, items }) => {
  return (
    <section>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.content}>
        {items.map((item, index) => (
          <StockCard key={index} stock={item} />
        ))}
      </div>
    </section>
  );
};

export default PredictionsSection;
