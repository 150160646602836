import React from "react";
import DateDisplay from "../DateDisplay/DateDisplay";
import logo from "../../assets/images/bbslogo.png";
import styles from "./Header.module.css";

const Header = ({ date }) => {
  return (
    <div className={styles.headerContainer}>
      <img
        src={logo}
        alt="Bullish Banter Society Logo"
        className={styles.logo}
      />
      <div className={styles.titleContainer}>
        <DateDisplay date={date} />
        <h1 className={styles.title}>WEEKLY FORECAST</h1>
      </div>
    </div>
  );
};

export default Header;
