import React from "react";
import "./StarRating.css";

const StarRating = ({ score }) => {
  const starCount = 5; // Total stars (5 stars)
  const starsToFill = (score / 10) * starCount;
  const fullStars = Math.floor(starsToFill);
  const partialStar = starsToFill - fullStars;

  return (
    <div className="star-rating">
      <div className="score">
        <p className="title">
          Financial Score: <strong>{score.toFixed(1)}/10</strong>
        </p>
      </div>
      <div className="stars">
        {Array.from({ length: starCount }, (_, i) => {
          if (i < fullStars) {
            return (
              <span key={i} className="star full">
                ★
              </span>
            );
          } else if (i === fullStars && partialStar > 0) {
            return (
              <span
                key={i}
                className="star partial"
                style={{ "--partial-width": `${partialStar * 100}%` }}
              >
                ★
              </span>
            );
          } else {
            return (
              <span key={i} className="star empty">
                ★
              </span>
            );
          }
        })}
      </div>
    </div>
  );
};

export default StarRating;
