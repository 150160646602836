import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import PredictionsSection from "./components/PredictionsSection/PredictionsSection";
import InformationCard from "./components/InformationCard/InformationCard";
import Footer from "./components/Footer/Footer";

const App = () => {
  const [gainers, setGainers] = useState([]);
  const [losers, setLosers] = useState([]);
  const [date, setDate] = useState("");

  useEffect(() => {
    // Fetch data from the API
    fetch("/data/newsletter.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setGainers(data.top_predicted_gainers);
        setLosers(data.top_predicted_losers);
        setDate(data.date);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, []);

  return (
    <div className="App">
      <header>
        <Header date={date} />
      </header>
      <main>
        <InformationCard></InformationCard>
        <PredictionsSection title="Our Top Predictions Picks" items={gainers} />
        <PredictionsSection
          title="Our Worst Predictions Picks"
          items={losers}
        />
        <Footer />
      </main>
    </div>
  );
};

export default App;
